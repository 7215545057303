.forceColor {
  color: var(--text-color);
}

.textWithIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.containerFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  border-width: 1px;
  max-width: 8rem;
  width: 100%;
  height: 2.8rem;
}

.spaceLeft {
  margin-left: 5px;
}
