.container {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.container a {
  text-align: center;
  max-width: 60%;
}

.imageContainer {
  position: relative;
  height: 100px;
  width: 500px;
}

@media (max-width: 500px) {
  .container {
    justify-content: flex-start;
    padding-left: 20px;
  }
  .container.center {
    justify-content: center;
    padding-left: 0;
  }
  .container a {
    text-align: left;
  }
  .imageContainer {
    position: relative;
    height: 100px;
    width: 100px;
  }
}
