.button {
  composes: primaryBackgroundColor from global;
  composes: buttonShadow from global;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}

.title {
  font-size: 16px;
}
