.container {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  composes: buttonShadow from global;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.icon {
  cursor: pointer;
  color: inherit;
}

.menuText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: bold;
  color: inherit;
  font-size: 18px;
  padding: 7px 10px;
  min-width: 100px;
}

.menuText > * {
  margin-left: 5px;
}

.menu {
  z-index: 500;
  position: absolute;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  overflow: hidden;
  right: 10px;
  top: 46px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
}

.menu a {
  display: inline-block;
  padding: 15px;
  flex: 1;
  cursor: pointer;
}

.menu a:hover {
  background-color: #f2f0f0;
}

.account {
  text-align: center;
}

.name {
  font-size: 16px;
  margin-bottom: 5px;
  color: #000;
}

.email {
  font-size: 13px;
  color: #454545;
}

.menuLink {
  font-size: 14px;
  color: #454545;
}

.menuLink span {
  position: relative;
  top: 1.5px;
  margin-left: 10px;
}

.divider {
  height: 1px;
  background-color: #eee;
}

.logoutIcons {
  display: flex;
  border-top: 1px solid #eee;
}

.logoutIcons .menuLink {
  font-size: 12px;
  text-align: center;
  padding: 10px 15px;
}

.logoutIcons .menuLink:last-child {
  border-left: 1px solid #eee;
}

.user-menu-enter,
.user-menu-leave.user-menu-leave-active {
  opacity: 0;
  transform: rotateX(-15deg);
  transform-origin: 50% -50px;
}

.user-menu-enter.user-menu-enter-active,
.user-menu-leave {
  opacity: 1;
  transform: rotateX(0deg);
  transition: all 300ms ease;
}
