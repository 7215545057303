.container {
  composes: mainNavbar from global;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 310;
}

.containerTableOrder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}

.flex {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.sections {
  flex: 1;
}
