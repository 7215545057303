.container {
  composes: navbarLinksContainer from global;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  padding: 0px 0 10px;
}

.link {
  composes: navbarLink from global;
  composes: appSubtitle from global;
  display: inline-block;
  padding: 5px 20px;
  font-weight: bold;
  font-size: 20px !important;
  margin: 0 !important;
  color: inherit;
}

.active {
  composes: link;
  composes: primaryColor from global;
  border-bottom: var(--websitePrimaryColor) solid 2px;
}

.megamenu {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  padding: 32px;
  z-index: 400;
}

.category {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  margin-bottom: 32px;
  align-items: flex-start;
}

.categoryTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #2e2e2e;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.categoryTitle svg {
  margin-left: 10px;
}

.subcategoryTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #000000;
  margin-bottom: 12px;
}

.seeAll {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.25px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
}

.seeAll svg {
  margin-left: 10px;
}
.triangle {
  height: 34px;
  width: 34px;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 17px solid white;
  border-top: 0px solid transparent;
  position: absolute;
}
