.closeMenu {
  background-color: var(--background-color);
  composes: backgroundBorderColor from global;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 300;
}

.container {
  top: 50px;
  left: 0;
  width: 100vw;
  min-height: calc(100vh - 50px);
  background-color: var(--lighter-background-color);
  z-index: 300;
  position: fixed;
}

.loadingContainer {
  composes: container;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories {
  height: 100vh;
  overflow: scroll;
}

.categoriesList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
}

.categoryItem {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.25px;
  margin-bottom: 12px;
  padding-left: 5px 0;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px 24px;
  border-bottom: 1px solid #eeeeee;
}

.header h2 {
  margin-left: 18px;
}

.primaryColor {
  color: var(--primary-color);
}
